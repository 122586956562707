import type { Pagination } from '@/types'
import { useUiStore } from '~/store/ui'

export default () => {
  const uiStore = useUiStore()
  const route = useRoute()
  return ref<Pagination>({
    current_page: Number(route.query.page) || 1,
    total_pages: 1,
    total: 0,
    count: 0,
    per_page: uiStore.getTableItemsPerPage || Number(route.query.limit) || 20,
  })
}
